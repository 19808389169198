<template>
  <div>
    <user-add></user-add>
  </div>
</template>

<script>
import UserAdd from './UserAdd.vue';

export default {
  components: {
    UserAdd,
  },

  data() {
    return {};
  },
};
</script>
